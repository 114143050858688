<template>
    <div>
        <div class="card card-default">
            <div class="card-header">
                <div class="float-right">
                    <form class="form-inline">
                        <select class="form-control" v-model="grill">
                            <option v-for="grill in grills" :value="grill">{{ grill.id }} | {{ grill.name }}</option>
                        </select>
                        <button type="button" class="btn btn-default" @click="saveGrill">Create Grill</button>
                    </form>
                </div>

                <h1>Grill Client</h1>
            </div>
            <div class="card-body">
                <table class="table">
                    <tr>
                        <th>Created at</th>
                        <th>Session</th>
                        <th>Data</th>
                    </tr>
                    <tr v-for="event in events">
                        <td>{{ event.created_at }}</td>
                        <td>{{ event.session }}</td>
                        <td>{{ event.dataPoint }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import Simulator from './Simulator';

    export default {

        mixins: [Simulator],

        data() {
            return {
                events: [],
            };
        },

        watch: {
            grill() {
                Echo.channel(`App.Grill.${this.grill.id}`)
                    .listen('Grill.TemperatureSensorChanged', (data) => {
                        this.events.push(data);
                    });
            },
        },
    };
</script>