<template>
    <div>
        <div class="card card-default">
            <div class="card-header">
                <div class="float-right">
                    <form class="form-inline">
                        <select class="form-control" v-model="grill">
                            <option v-for="grill in grills" :value="grill">{{ grill.id }} | {{ grill.name }}</option>
                        </select>
                        <button type="button" class="btn btn-default" @click="saveGrill">Create Grill</button>
                    </form>
                </div>

                <h1>Grill Simulator</h1>
            </div>
            <div class="card-body">
                <simulator-datapoint :data-point="dataPoint"></simulator-datapoint>

                <hr>

<!--                <div class="float-right">-->
<!--                    <button type="button" class="btn btn-success" @click="saveDataPoint" :disabled="!session || (intervalPid > 0)">-->
<!--                        Single new Datapoint-->
<!--                    </button>-->
<!--                    <button type="button" class="btn btn-success" @click="autoPilot" :disabled="!session" v-if="intervalPid === 0">-->
<!--                        Start Simulation-->
<!--                    </button>-->
<!--                    <button type="button" class="btn btn-danger" @click="autoPilot" v-else>-->
<!--                        Stop Simulation-->
<!--                    </button>-->
<!--                </div>-->
                <div>
                    <button type="button" class="btn btn-default" @click="saveSession" v-if="!session">
                        Create Session
                    </button>
                    <button type="button" class="btn btn-warning" @click="resetSession" :disabled="intervalPid > 0" v-else>
                        Reset Session
                    </button>
                </div>
            </div>
        </div>

        <div id="accordion">
            <div class="card my-2" v-for="dataPoint in history">
                <div class="card-header">
                    <h5 class="mb-0">
                        <button class="btn btn-link" data-toggle="collapse" :data-target="`#collapse${dataPoint._id}`">
                            DataPoint ({{ dataPoint._id }})
                        </button>
                    </h5>
                </div>
                <div :id="`collapse${dataPoint._id}`" class="collapse">
                    <div class="card-body">
                        <simulator-datapoint :data-point="dataPoint"></simulator-datapoint>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Simulator from './Simulator';

    export default {

        mixins: [Simulator],

        data() {
            return {
                session: null,
                dataPoint: this.randomDataPoint(),
                history: [],
                intervalPid: 0,
            };
        },

        methods: {

            randomInt(min, max)
            {
                min = Math.ceil(min);
                max = Math.floor(max);
                return Math.floor(Math.random() * (max - min)) + min;
            },

            randomSession()
            {
                return {
                    "name": `Big Fat BBQ at ${new Date()}`,
                };
            },

            randomDataPoint()
            {
                return {
                    "operation_mode": "auto",
                    "target_lift_level": this.randomInt(0, 100),
                    "lift_level": this.randomInt(0, 100),
                    "power_mode": "charging",
                    "batterie_level": this.randomInt(0, 100),
                    "connection": "wlan",
                    "device_status": 0,
                    "network_status": 0,
                    "target_temperature": this.randomInt(0, 3000),
                    "temperatures": [
                        this.randomInt(0, 6000), // Rost
                        this.randomInt(0, 1200), // Sensor 1
                        this.randomInt(0, 1200), // Sensor 2
                        this.randomInt(0, 1200), // Sensor 3
                        this.randomInt(0, 1200), // Sensor 4
                    ],
                    "fans": [
                        this.randomInt(0, 100), // Left
                        this.randomInt(0, 100) // Right
                    ]
                };
            },

            saveSession()
            {
                return axios.post(`/api/grills/${this.grill.id}/sessions`, this.randomSession())
                    .then((response) => {
                        this.session = response.data;
                    }).catch((error) => {
                        console.error("Error creating session.", error);
                    });
            },

            resetSession()
            {
                this.session = null;
            },

            // NOTE since microservices handle data points we can no longer create them here
            // as our auth token does not have the 'services' scope but the 'user' scope instead
            // this token behaves exactly like any other application
            // saveDataPoint()
            // {
            //     return axios.post(`/api/sessions/${this.session.id}/data-points`, this.randomDataPoint())
            //         .then((response) => {
            //             this.dataPoint = response.data;
            //             this.history.unshift(response.data);
            //
            //             console.log("Saved datapoint at backend.");
            //         }).catch((error) => {
            //             console.error("Error creating data point.", error);
            //         });
            // },
            //
            // autoPilot()
            // {
            //     if (this.intervalPid === 0) {
            //         // Start timer.
            //         this.intervalPid = setInterval(this.saveDataPoint, 3000);
            //     } else {
            //         // Stop timer.
            //         clearInterval(this.intervalPid);
            //         this.intervalPid = 0;
            //     }
            // },
        },
    };
</script>
