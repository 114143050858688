import axios from 'axios';

export default {

    data() {
        return {
            grills: [],
            grill: null,
        };
    },

    methods: {
        randomIdent() {
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (var i = 0; i < 5; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            }
            return text;
        },

        randomGrill() {
            return {
                "name": "Grill Simulator",
                "ident": this.randomIdent(),
                "firmware_version": "1.0",
                "hardware_version": "1.0"
            };
        },

        saveGrill() {
            return axios.post(`/api/grills`, this.randomGrill())
                .then((response) => {
                    this.grill = response.data;

                    this.loadGrills();
                }).catch((error) => {
                    console.error("Error creating grill.", error);
                });
        },

        loadGrills() {
            return axios.get(`/api/grills`)
                .then((response) => {
                    this.grills = response.data;

                    if (this.grills.length > 0) {
                        this.grill = this.grills[0];
                    }
                });
        },
    },

    mounted() {
        this.loadGrills();
    },
};
