<template>
    <div>
        <h2><span class="glyphicon glyphicon-fire"></span> Temperatures</h2>
        <div class="row">
            <div class="col-sm-12">Rost: <b>{{ dataPoint.temperatures[0] }}</b></div>
            <div class="col-sm-12">Ziel Temp.: <b>{{ dataPoint.target_temperature }}</b></div>
            <div class="col-sm-3">Temp1: <b>{{ dataPoint.temperatures[1] }}</b></div>
            <div class="col-sm-3">Temp2: <b>{{ dataPoint.temperatures[2] }}</b></div>
            <div class="col-sm-3">Temp3: <b>{{ dataPoint.temperatures[3] }}</b></div>
            <div class="col-sm-3">Temp4: <b>{{ dataPoint.temperatures[4] }}</b></div>
        </div>

        <hr>

        <h2><span class="glyphicon glyphicon-dashboard"></span> Fans</h2>
        <div class="row">
            <div class="col-sm-6">Fan: <b>{{ dataPoint.fans[0] }}</b></div>
        </div>

        <hr>

        <h2><span class="glyphicon glyphicon-signal"></span> Status</h2>
        <div class="row">
            <div class="col-sm-6">Operation Mode: <b>{{ dataPoint.operation_mode }}</b></div>
            <div class="col-sm-6">Ziel Lift Level: <b>{{ dataPoint.target_lift_level }}</b></div>
            <div class="col-sm-6">Lift Level: <b>{{ dataPoint.lift_level }}</b></div>
            <div class="col-sm-6">Power Mode: <b>{{ dataPoint.power_mode }}</b></div>
            <div class="col-sm-6">Batterie Level: <b>{{ dataPoint.batterie_level }}</b></div>
            <div class="col-sm-6">Connection: <b>{{ dataPoint.connection }}</b></div>
            <div class="col-sm-6">Device: <b>{{ dataPoint.device_status }}</b></div>
            <div class="col-sm-6">Network: <b>{{ dataPoint.network_status }}</b></div>
        </div>
    </div>
</template>

<script>
    export default {

        props: {
            dataPoint: {
                type: Object,
                required: true,
            },
        }
    };
</script>